import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getInstance } from '../../../services/instance';
import { IVideoContext } from './interfaces';
import { GetVideoRequest } from '@wix/challenges-web-video-serverless/types';
import { getChallengeData } from '../Challenge';
import { isV3enabled } from '../../../experiments/isV3enabled';

export const getVideoInitialData = (flowAPI: ControllerFlowAPI) => {
  return {
    requestVideoData: async (stepId: string) => {
      const challengeData = await getChallengeData(flowAPI);
      const instanceFromUrl = getInstance(flowAPI);
      const v3enabled = isV3enabled(flowAPI);

      flowAPI.controllerConfig.setProps({
        isVideoRequestInProgress: true,
      });

      try {
        const params: GetVideoRequest = v3enabled
          ? { stepId }
          : {
              stepId,
              programId: challengeData?.challenge?.id,
              stepVersion: 'legacy',
            };
        const videoDataResponse = await flowAPI.httpClient.get(
          `/_serverless/challenges-web-video-serverless/video/${stepId}`,
          {
            params,
            headers: {
              Authorization: instanceFromUrl,
            },
          },
        );

        if (videoDataResponse.status === 200) {
          flowAPI.controllerConfig.setProps({
            videoData: videoDataResponse.data,
            isVideoRequestInProgress: false,
            videoDataRequestError: null,
          });
        } else {
          const error = new Error("Can't get video data.");

          flowAPI.controllerConfig.setProps({
            videoData: null,
            isVideoRequestInProgress: false,
            videoDataRequestError: error,
          });

          handleError({
            error,
            context: 'requestVideoData.requestError',
          });
        }
      } catch (error) {
        flowAPI.controllerConfig.setProps({
          videoData: null,
          isVideoRequestInProgress: false,
          videoDataRequestError: error,
        });

        handleError({
          error,
          context: 'requestVideoData.generalError',
        });
      }
    },
    videoData: null,
    isVideoRequestInProgress: false,
    videoDataRequestError: null,
    requestHLSStreamURL: async (stepId: string) => {
      const instanceFromUrl = getInstance(flowAPI);
      const challengeData = await getChallengeData(flowAPI);
      const v3enabled = isV3enabled(flowAPI);

      flowAPI.controllerConfig.setProps({
        isHLSStreamURLRequestInProgress: true,
      });

      try {
        const params = v3enabled
          ? { stepId }
          : {
              stepVersion: 'legacy',
              programId: challengeData.challenge.id,
              stepId,
            };
        const HLSStreamURLResponse = await flowAPI.httpClient.get(
          `/_serverless/challenges-web-video-serverless/generate-stream-url/${stepId}`,
          {
            params,
            headers: {
              Authorization: instanceFromUrl,
            },
          },
        );

        if (HLSStreamURLResponse.status === 200) {
          flowAPI.controllerConfig.setProps({
            HLSStreamURL: HLSStreamURLResponse.data,
            isHLSStreamURLRequestInProgress: false,
            HLSStreamURLRequestError: null,
          });
        } else {
          const error = new Error("Can't get video view url.");

          flowAPI.controllerConfig.setProps({
            HLSStreamURL: null,
            isHLSStreamURLRequestInProgress: false,
            HLSStreamURLRequestError: error,
          });

          handleError({
            error,
            context: 'requestHLSStreamURL.requestError',
          });
        }
      } catch (error) {
        flowAPI.controllerConfig.setProps({
          HLSStreamURL: null,
          isHLSStreamURLRequestInProgress: false,
          HLSStreamURLRequestError: error,
        });

        handleError({
          error,
          context: 'requestHLSStreamURL.generalError',
        });
      }
    },
    HLSStreamURL: null,
    isHLSStreamURLRequestInProgress: false,
    HLSStreamURLRequestError: null,
    resetVideoState: () => {
      flowAPI.controllerConfig.setProps({
        videoData: null,
        isVideoRequestInProgress: false,
        videoDataRequestError: null,
        HLSStreamURL: null,
        isHLSStreamURLRequestInProgress: false,
        HLSStreamURLRequestError: null,
      });
    },
  } as IVideoContext;
};
